
    import { defineComponent, ref } from 'vue';
    import { ApproveNode, Role } from "@/views/Approve/MgRoleApprove/PackageDetail/types";

    export default defineComponent({
        name: "ApproveSteps",
        props: {
            data: {
                type: Array,
                default() {
                    return []
                }
            }
        },
        setup() {
            //是否展开
            const isOpenSteps = ref<boolean>(false);
            const toggleOpen = (): void => {
                isOpenSteps.value = !isOpenSteps.value;
            }
            //根据状态来获取对应的class
            const getStatusMappingClass = (data: ApproveNode, role: Role): string => {
                if (data.approvalStatus === 'approvaled') {
                    return 'approve';
                }
                if (data.approvalStatus === 'approvaling') {
                    if (role.flowUser && role.flowUser.approvalStatus === 'approvaled') {
                        return 'ongoing-approve-approved'

                    } else if (role.flowUser && role.flowUser.approvalStatus === 'approvaling' || role.pendingApproval) {
                        return 'ongoing-approve';
                    }
                }
                return '';
            }
            return {
                isOpenSteps,
                toggleOpen,
                getStatusMappingClass
            }
        }
    })
