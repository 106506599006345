
import { defineComponent, ref, reactive, toRefs, onMounted, computed, watch } from 'vue';
import moment from 'moment';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { CreatePackageRes } from './types';
import { Program } from '@/views/Approve/SalesRoleApprove/ProgramHome/types';
import {
  createPackage,
  getSubscribedBu,
  savePackage,
  submitCreatePackage,
  getProgramHomePageData,
  getPackageDetail,
  findApproveProgressByBu,
  getApproveProgress,
  findApprovalListBybu,
  getApprovalChainPath,
  sendApprovalRemindNoticeByBatchId
} from '@/API/approve';
import AddProgram from '../components/AddProgram.vue';
import ApproveSteps from '../../components/ApproveSteps.vue';
import { message, Modal } from 'ant-design-vue';
import useFetch from '@/hooks/useFetch';
import { BuItem } from '@/views/Approve/SalesRoleApprove/ProgramHome/types';
import router from '@/router';
import { ApproveProgressResponse } from '@/views/Approve/MgRoleApprove/PackageDetail/types';
import deepClone from '@/utils/deepClone';
import { toThousands } from '@/utils/approve';

type Key = ColumnProps['key'];

const sourceColumns = [
  {
    title: 'Program Code',
    dataIndex: 'code',
    key: 'programCode',
    width: 220,
    ellipsis: true
  },
  {
    title: 'Program Name',
    dataIndex: 'name',
    key: 'programName',
    width: 240,
    ellipsis: true
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    width: 160,
    align: 'right'
  },
  {
    title: 'Submit Date',
    dataIndex: 'updatedAt',
    key: 'submitDate',
    slots: { customRender: 'submitDate' },
    width: 160
  },
  {
    title: 'Requestor',
    dataIndex: 'requestor',
    key: 'requestor',
    width: 160
  }
];

export default defineComponent({
  name: 'Program Batch',
  props: {
    batchId: {
      type: String
    }
  },
  components: { AddProgram, ApproveSteps },
  setup(props) {
    const { replace } = useRouter();
    const { state, commit } = useStore();

    const isOpen = ref(false);
    const handleCollapse = () => {
      isOpen.value = !isOpen.value;
      // nextTick(() => {
      //     const tablePosition = useTableHeight(true, '.pagination-container', true);
      //     tableHeight.value = tablePosition.tableHeight.value
      // })
    };

    const columns = ref<any[]>([]);

    //判断batchStatus状态为approvaling和Approved禁用按钮
    const disableBatchStatus: string[] = ['Approved', 'Approving'];

    //查询bu options
    const { data: buOptions } = useFetch<BuItem[]>({}, [], getSubscribedBu);

    //当前的package的数据
    const packageData = reactive<CreatePackageRes>({
      batchName: '',
      bu: '',
      batchId: '',
      listBudget: [],
      programDto: [],
      status: '',
      createdAt: '',
      totalBudget: 0,
      sysBatchName: ''
    });

    //需要验证的数据
    const validateData = reactive<{ bu: string; batchName: string; approveLine: string }>({
      bu: '',
      batchName: '',
      approveLine: ''
    });

    //ApproveProgress
    const approveProgressData = reactive<ApproveProgressResponse>({
      id: '',
      buName: '',
      batchId: '',
      totalNodes: 0,
      approvaledNodes: '',
      status: '',
      nodeList: []
    });

    //状态为Confirmed的program,添加的数据
    const programList = ref<Program[]>([]);

    //是否展开
    const isOpenSteps = ref<boolean>(false);

    //是否显示add program 弹窗
    const visibleAddProgramModal = ref(false);

    const rowSelection = reactive<{ selectedRowKeys: Key[]; onChange: Function }>({
      onChange: (selectedRowKeys: Key[]) => {
        rowSelection.selectedRowKeys = selectedRowKeys;
      },
      selectedRowKeys: []
    });

    const apprpveList = ref<string[]>([]);
    // 获取appove列表
    const getApprovalData = (bu: string) => {
      findApprovalListBybu({ params: { bu } }).then((res) => {
        console.log(res);
        apprpveList.value = res;
      });
    };

    //查看审批进度
    const getApproveProgressByBu = () => {
      if (disableBatchStatus.includes(packageData.status as string)) {
        getApproveProgress({ params: { batchId: props.batchId as string } }).then((data) => {
          Object.assign(approveProgressData, data);
        });
      } else {
        // findApproveProgressByBu({ params: { bu: packageData.bu as string }}).then(data => {
        //     Object.assign(approveProgressData, data);
        // })
        if (validateData.approveLine) {
          getApprovalChainPath({ params: { chainName: validateData.approveLine } }).then((res) => {
            approveProgressData.nodeList = res;
          });
        }
      }
    };

    //在program home页面点击created package 进入获取数据
    const findBatchDataByBatchId = () => {
      getPackageDetail({ params: { batchId: props.batchId as string } }).then((data) => {
        //处理正数
        data.programDto.forEach((item) => {
          for (const key in item) {
            if (typeof item[key] === 'number') {
              item[key] = toThousands(item[key]);
            }
          }
        });
        data.createdAt = data.createdAt ? moment(data.createdAt).format('YYYY-MM-DD') : '';
        Object.assign(packageData, data);
        //同步到validateData
        Object.assign(validateData, data);
        Object.assign(validateData, {
          bu: data.bu,
          batchName: `${data.sysBatchName}${data.batchName}`,
          approveLine: data.approvalChainName
        });
        //根据返回的listBudget生产columns
        const budgetColumns = data.listBudget.map((item) => ({
          title: item.make,
          dataIndex: item.col,
          key: item.make,
          width: 160,
          align: 'right'
        }));
        const _columns = deepClone(sourceColumns);
        _columns.splice(2, 0, ...budgetColumns);
        columns.value = _columns;

        //查询审批进度
        getApproveProgressByBu();

        getApprovalData(validateData.bu);
      });
    };

    const findBatchDataByProgramId = () => {
      const body = {
        batchName: '',
        bu: '',
        programs: state.approve.programIds,
        batchId: props.batchId
      };
      createPackage(body).then((data) => {
        data.createdAt = data.createdAt ? moment(data.createdAt).format('YYYY-MM-DD') : '';
        //处理正数
        data.programDto.forEach((item) => {
          for (const key in item) {
            if (typeof item[key] === 'number') {
              item[key] = toThousands(item[key]);
            }
          }
        });
        Object.assign(packageData, data);
        //赋值默认Bu
        Object.assign(validateData, { bu: data.bu });
        //根据返回的listBudget生产columns
        const budgetColumns = data.listBudget.map((item) => ({
          title: item.make,
          dataIndex: item.col,
          key: item.make,
          width: 160
        }));
        //根据返回的buName 过滤出bu 对应的orgId
        const _columns = deepClone(sourceColumns);
        _columns.splice(2, 0, ...budgetColumns);
        columns.value = _columns;

        //查询审批进度
        getApproveProgressByBu();

        getApprovalData(validateData.bu);
      });
    };

    const handlerWithdrawClick = (): void => {
      Modal.confirm({
        title: '提示',
        content: 'Withdraw form Batch Approve ?',
        onOk() {
          console.log('OK');
        },
        onCancel() {
          console.log('Cancel');
        }
      });
    };
    const handleChangeLine = () => {
      if (validateData.approveLine) {
        getApprovalChainPath({ params: { chainName: validateData.approveLine } }).then((res) => {
          approveProgressData.nodeList = res;
        });
      }
    };
    const handlerSaveClick = (): void => {
      if (!validateData.batchName) {
        message.error('请输入batchName');
        return;
      }
      if (!validateData.bu) {
        message.error('请选择bu');
        return;
      }

      if (!validateData.approveLine) {
        message.error('请选择Apprrove Line');
        return;
      }

      const body = {
        bu: validateData.bu,
        approvalChainName: validateData.approveLine,
        batchId: props.batchId,
        batchName: validateData.batchName.includes(packageData.sysBatchName)
          ? validateData.batchName.slice(packageData.sysBatchName.length)
          : validateData.batchName,
        programs: packageData.programDto.map((item) => ({ programId: item.programId }))
      };
      handleChangeLine();
      savePackage(body).then((data) => {
        message.success('Save success');
        //为了实现记录batchId，防止刷新丢状态
        replace({ path: '/approval/sales/createPackage', query: { batchId: data.batchId } });
      });
    };

    const handlerSubmitClick = (): void => {
      if (!packageData.batchId || !props.batchId) {
        message.error('请先保存后在提交');
        return;
      }
      submitCreatePackage({ batchId: packageData.batchId || props.batchId }).then((data) => {
        message.success('Submit success');
        setTimeout(() => {
          router.push('/approval/sales/packageHome');
        }, 1500);
      });
    };

    const handlerAddClick = (): void => {
      getProgramHomePageData({ params: { status: 'Confirmed', bu: [packageData.buId as string] } }).then((data) => {
        const programs = data.content.filter((item) => {
          //过滤已经选的program
          return (
            !packageData.programDto.some((program) => program.programId == item.programId) && !item.batchApproveName
          );
        });
        programList.value = programs;
        visibleAddProgramModal.value = true;
      });
    };

    const handlerRemoveClick = (): void => {
      if (rowSelection.selectedRowKeys.length < 1) {
        message.error('最少保留一条program');
        return;
      }
      const programs = packageData.programDto
        .filter((item, index) => !rowSelection.selectedRowKeys.includes(item.programId))
        .map((item) => ({ programId: item.programId }));
      commit('approve/updateProgramIds', programs);
      findBatchDataByProgramId();
    };

    const handlerConfirmClick = (programs: { programId: string }[]): void => {
      const programIds = packageData.programDto.map((item) => ({ programId: item.programId }));
      commit('approve/updateProgramIds', [...programIds, ...programs]);
      findBatchDataByProgramId();
    };

    const toggleOpen = (): void => {
      isOpenSteps.value = !isOpenSteps.value;
    };

    const emailAlertDialogVisible = ref(false);

    const approvers = computed(() => {
      const userNames = new Set<string>();
      approveProgressData.nodeList.forEach((nodeItem) => {
        if (nodeItem.approvalStatus !== 'Approving') {
          return;
        }
        nodeItem.roleDtoList.forEach((roleDtoItem) => {
          if (roleDtoItem.flowUser?.approvalStatus !== 'Approved' && roleDtoItem.flowUser?.userName) {
            userNames.add(roleDtoItem.flowUser?.userName);
          }
        });
      });
      return Array.from(userNames).join(',');
    });

    const handleShowEmailAlertDialog = () => {
      emailAlertDialogVisible.value = true;
    };

    const handleSend = async () => {
      await sendApprovalRemindNoticeByBatchId(props.batchId as string);
      emailAlertDialogVisible.value = false;
      message.success('Send successfully!');
    };

    watch(
      () => props.batchId,
      (value) => {
        findBatchDataByBatchId();
      }
    );

    onMounted(() => {
      if (props.batchId) {
        findBatchDataByBatchId();
      } else {
        findBatchDataByProgramId();
      }
    });

    return {
      rowSelection,
      columns,
      visibleAddProgramModal,

      //事件处理
      handlerWithdrawClick,
      handlerSaveClick,
      handlerSubmitClick,
      handlerAddClick,
      handlerRemoveClick,
      handlerConfirmClick,

      toggleOpen,
      isOpenSteps,

      ...toRefs(packageData),

      //select options
      buOptions,

      //moment
      moment,

      //需要验证的数据
      validateData,

      //program list
      programList,

      //审批进度
      approveProgressData,

      toThousands,

      //需要禁用的batchStatus
      disableBatchStatus,

      apprpveList,
      isOpen,
      handleCollapse,
      handleChangeLine,

      emailAlertDialogVisible,
      approvers,
      handleShowEmailAlertDialog,
      handleSend
    };
  }
});
